import { ADD_TO_CART, CLEAR_CART, REMOVE_FROM_CART } from "./types"

const useActions = (prevState, dispatch) => {
  const actions = {
    addToCart: payload => {
      dispatch({ type: ADD_TO_CART, payload })
    },
    removeFromCart: payload => {
      dispatch({ type: REMOVE_FROM_CART, payload })
    },
    clearCart: () => {
      dispatch({ type: CLEAR_CART })
    },
  }
  return actions
}

export default useActions
