import {
  SET_CATEGORIES,
  SET_DELIVERY_CITIES,
  SET_FILTERED_PRODUCTS,
  SET_PRODUCTS,
  SET_SEARCH_STRING,
} from "./types"

const useActions = (prevState, dispatch) => {
  const actions = {
    setProducts: payload => {
      dispatch({ type: SET_PRODUCTS, payload })
    },
    setCategories: payload => {
      dispatch({ type: SET_CATEGORIES, payload })
    },
    setFilteredProducts: payload => {
      dispatch({ type: SET_FILTERED_PRODUCTS, payload })
    },
    setSearchString: payload => {
      dispatch({ type: SET_SEARCH_STRING, payload })
    },
    setDeliveryCities: payload => {
      dispatch({ type: SET_DELIVERY_CITIES, payload })
    },
  }
  return actions
}

export default useActions
