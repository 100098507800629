exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-index-js": () => import("./../../../src/pages/about-us/index.js" /* webpackChunkName: "component---src-pages-about-us-index-js" */),
  "component---src-pages-agricultural-consultancy-index-jsx": () => import("./../../../src/pages/agricultural-consultancy/index.jsx" /* webpackChunkName: "component---src-pages-agricultural-consultancy-index-jsx" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-blog-post-js": () => import("./../../../src/pages/blog/_post.js" /* webpackChunkName: "component---src-pages-blog-post-js" */),
  "component---src-pages-cart-cancelled-js": () => import("./../../../src/pages/cart/cancelled.js" /* webpackChunkName: "component---src-pages-cart-cancelled-js" */),
  "component---src-pages-cart-index-js": () => import("./../../../src/pages/cart/index.js" /* webpackChunkName: "component---src-pages-cart-index-js" */),
  "component---src-pages-cart-success-js": () => import("./../../../src/pages/cart/success.js" /* webpackChunkName: "component---src-pages-cart-success-js" */),
  "component---src-pages-contact-us-index-js": () => import("./../../../src/pages/contact-us/index.js" /* webpackChunkName: "component---src-pages-contact-us-index-js" */),
  "component---src-pages-delivery-information-index-js": () => import("./../../../src/pages/delivery-information/index.js" /* webpackChunkName: "component---src-pages-delivery-information-index-js" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-modern-agro-gardens-index-jsx": () => import("./../../../src/pages/modern-agro-gardens/index.jsx" /* webpackChunkName: "component---src-pages-modern-agro-gardens-index-jsx" */),
  "component---src-pages-online-orders-index-js": () => import("./../../../src/pages/online-orders/index.js" /* webpackChunkName: "component---src-pages-online-orders-index-js" */),
  "component---src-pages-organic-store-[index]-js": () => import("./../../../src/pages/organic-store/[index].js" /* webpackChunkName: "component---src-pages-organic-store-[index]-js" */),
  "component---src-pages-organic-store-index-jsx": () => import("./../../../src/pages/organic-store/index.jsx" /* webpackChunkName: "component---src-pages-organic-store-index-jsx" */),
  "component---src-pages-our-partner-farms-farm-js": () => import("./../../../src/pages/our-partner-farms/_farm.js" /* webpackChunkName: "component---src-pages-our-partner-farms-farm-js" */),
  "component---src-pages-our-partner-farms-index-js": () => import("./../../../src/pages/our-partner-farms/index.js" /* webpackChunkName: "component---src-pages-our-partner-farms-index-js" */),
  "component---src-pages-price-list-index-js": () => import("./../../../src/pages/price-list/index.js" /* webpackChunkName: "component---src-pages-price-list-index-js" */),
  "component---src-pages-privacy-policy-index-js": () => import("./../../../src/pages/privacy-policy/index.js" /* webpackChunkName: "component---src-pages-privacy-policy-index-js" */),
  "component---src-pages-products-beetroot-jsx": () => import("./../../../src/pages/products/beetroot.jsx" /* webpackChunkName: "component---src-pages-products-beetroot-jsx" */),
  "component---src-pages-products-cabbage-jsx": () => import("./../../../src/pages/products/cabbage.jsx" /* webpackChunkName: "component---src-pages-products-cabbage-jsx" */),
  "component---src-pages-products-carrot-index-jsx": () => import("./../../../src/pages/products/carrot/index.jsx" /* webpackChunkName: "component---src-pages-products-carrot-index-jsx" */),
  "component---src-pages-products-green-beans-jsx": () => import("./../../../src/pages/products/green-beans.jsx" /* webpackChunkName: "component---src-pages-products-green-beans-jsx" */),
  "component---src-pages-products-papaya-jsx": () => import("./../../../src/pages/products/papaya.jsx" /* webpackChunkName: "component---src-pages-products-papaya-jsx" */),
  "component---src-pages-products-potato-jsx": () => import("./../../../src/pages/products/potato.jsx" /* webpackChunkName: "component---src-pages-products-potato-jsx" */),
  "component---src-pages-products-pumkin-jsx": () => import("./../../../src/pages/products/pumkin.jsx" /* webpackChunkName: "component---src-pages-products-pumkin-jsx" */),
  "component---src-pages-products-sessile-joyweed-jsx": () => import("./../../../src/pages/products/sessile-joyweed.jsx" /* webpackChunkName: "component---src-pages-products-sessile-joyweed-jsx" */),
  "component---src-pages-products-sweet-banana-jsx": () => import("./../../../src/pages/products/sweet-banana.jsx" /* webpackChunkName: "component---src-pages-products-sweet-banana-jsx" */),
  "component---src-pages-products-sweet-potato-jsx": () => import("./../../../src/pages/products/sweet-potato.jsx" /* webpackChunkName: "component---src-pages-products-sweet-potato-jsx" */),
  "component---src-pages-refund-policy-index-jsx": () => import("./../../../src/pages/refund-policy/index.jsx" /* webpackChunkName: "component---src-pages-refund-policy-index-jsx" */)
}

